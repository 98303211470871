<template>
  <div>
    <div style="display: flex; align-items: center" v-if="content.show_constructor_categories">
      <div style="display: flex; align-items: center">
        <v-btn
          style="
            margin-left: auto;
            margin-right: 10px;
            margin-top: 15px;
            display: flex;
          "
          class="mb-4"
          color="primary"
          v-if="content.show_constructor_categories"
          depressed
          outlined
          small
          @click="$emit('groupOpen')"
        >
          <v-icon>mdi-chevron-left</v-icon>Listing list
        </v-btn>
      </div>
      <v-btn
        style="
          margin-left: auto;
          margin-right: 10px;
          margin-top: 15px;
          display: flex;
        "
        class="mb-4"
        color="primary"
        depressed
        outlined
        small
        @click="addListing(listings)"
      >
        + Add listing item
      </v-btn>
      <slot/>
    </div>
<!--    <v-data-table-->
<!--      class="table"-->
<!--      :headers="headers"-->
<!--      style="margin-bottom: 15px;"-->
<!--      :items-per-page="-1"-->
<!--      :items="listings"-->
<!--      hide-default-footer-->
<!--    >-->
<!--      <template v-slot:body="{ items }">-->
<!--        <draggable-->
<!--            :options="{disabled: isMobile}" :list="listings" tag="tbody" v-if="items.length">-->
<!--          <tr v-for="(item, index) in items" :key="item.id"  @click="$emit('editItem', index)" style="cursor: pointer">-->
<!--            <td class="d-block d-sm-table-cell" style="text-align: center">-->
<!--              <img-->
<!--                :src="-->
<!--                  item.main_img ? item.main_img : require('@/assets/none.png')-->
<!--                "-->
<!--                alt=""-->
<!--                style="max-height: 40px; max-width: 40px"-->
<!--              />-->
<!--            </td>-->
<!--            <td class="d-block d-sm-table-cell" style="text-align: center">-->
<!--              {{ item.name }}-->
<!--            </td>-->
<!--            <td class="d-block d-sm-table-cell" style="text-align: center">-->
<!--              <v-chip-->
<!--                class="ma-2"-->
<!--                :color="item.hide ? 'green' : 'red'"-->
<!--                text-color="white"-->
<!--                style="cursor: pointer"-->
<!--                @click.stop="changeActive(item)"-->
<!--              >-->
<!--                {{ item.hide ? "Active" : "Inactive" }}-->
<!--              </v-chip>-->
<!--            </td>-->

<!--            <td class="d-block d-sm-table-cell" style="text-align: center">-->
<!--              <v-menu offset-y open-on-hover bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>-->
<!--                    <v-icon>mdi-cog</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <v-list>-->
<!--                  <v-list-item-->
<!--                    style="cursor: pointer"-->
<!--                    @click="$emit('editItem', index)"-->
<!--                  >-->
<!--                    <v-list-item-title>Edit</v-list-item-title>-->
<!--                  </v-list-item>-->
<!--                  <v-list-item>-->
<!--                    <v-list-item-title-->
<!--                      style="cursor: pointer; color: red"-->
<!--                      @click="deleteListing(index)"-->
<!--                      >Delete</v-list-item-title-->
<!--                    >-->
<!--                  </v-list-item>-->
<!--                </v-list>-->
<!--              </v-menu>-->
<!--            </td>-->
<!--          </tr>-->
<!--        </draggable>-->
<!--        <tbody v-if="items.length === 0">-->
<!--          <tr>-->
<!--            <td class="d-block d-sm-table-cell"-->
<!--              colspan="7"-->
<!--              style="text-align: center; padding: 0 15px; font-weight: normal"-->
<!--            >-->
<!--              You don't have listing yet, it's time to create them-->
<!--            </td>-->
<!--          </tr>-->
<!--        </tbody>-->
<!--      </template>-->
<!--    </v-data-table>-->
    <div style="display:flex;flex-direction:column">
      <div v-for="(listing,index) in listings" :style="`border-bottom:${index!==listings.length-1?'1px solid #ddd;':'none'};`" :key="listing.id" style="display: flex;justify-content: space-between;padding:15px 0;">
        <div style="display: flex;">
          <div style="padding:0 15px;">
            <v-switch  v-model="listing.hide" color="rgb(106, 182, 74)" small hide-details style="padding: 0;margin: 0" />
          </div>
          <div style="display:flex;flex-direction:column">
            <p style="font-weight:bold;">{{listing.name}}</p>
            <p v-html="listing.full_descr"></p>
            <div>
              <v-btn
                  small
                  outlined
                  color="primary"
                  style="cursor: pointer;"
                  @click="$emit('editItem', {item:index,group:selectedGroupIndex})">
                Edit
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <v-btn color="primary"  text dark small
                 @click="deleteListing(index)" outlined>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <PopupDelete
      ref="PopupDeleteListing"
      title="Are you sure about delete listing?"
      @confirm="deleteListingConfirm"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import PopupDelete from "@/components/Popup/PopupDelete";
import defaults from "@/store/landing";
import draggable from "vuedraggable";
export default {
  name: "ListingList",
  props: ["listings","content","selectedGroupIndex"],
  components: {
    PopupDelete,
    // draggable,
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data() {
    return {
      isMobile:false,
      deleteListingID: null,
      headers: [
        { text: "Image", value: "main_img", align: "center", sortable: true },
        {
          text: "Name",
          value: "name",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "hide",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
    };
  },
  computed: {},
  methods: {
    deleteListingConfirm() {
      this.listings.splice(this.deleteListingID, 1);
      this.deleteListingID = null;
    },
    changeActive(item) {
      item.hide = !item.hide;
    },
    async deleteListing(listingIndex) {
      this.deleteListingID = listingIndex;
      this.$refs.PopupDeleteListing.open();
    },
    addListing(arr) {
      let listingDefault = {
        img: [],
        video_link: null,
        name: "default name",
        descr: "default description",
        main_img: "",
        preview: false,
        full_descr: "default full description",
        price: "100",
        show_price: true,
        show_descr: true,
        name_color: "#000000",position:"center center",background_color:"rgba(255,255,255,1)",
        hide: true,
        descr_color: "#000000",
        show_name_color: false,
        show_descr_color: false,
        id: 1,
        form: JSON.parse(JSON.stringify(defaults.defaultFormMenuItem)),
      };
      if (arr.length) {
        listingDefault.id = arr[arr.length - 1].id + 1;
      } else {
        listingDefault.id = 1;
      }
      this.listingGroupItemIndex = arr.length - 1;
      arr.push(listingDefault);
    },
  },
};
</script>

<style scoped></style>
