<template>
  <div>
    <div v-if="localPage === 'table'">
      <v-data-table
        class="table"
        :headers="headers"
        :items="groups"
        mobile-breakpoint="0"
        hide-default-footer
      >
        <template v-slot:body="{ items }">
          <draggable
              :options="{disabled: isMobile}" :list="groups" tag="tbody" v-if="items.length">
            <tr v-for="(item, i) in items" :key="item.id">
              <td  style="width: 300px;text-align: center" @click="$emit('changePage', i)">
                <div style="display: flex;align-items: center;flex-direction: column;">
                  <label class="label" style="  width: 300px;
                                                  overflow:hidden;
                                                  text-overflow: ellipsis;
                                                  white-space: nowrap;margin: 0 auto;display: block">{{getText(item.title)}}</label>
                  <label class="label" style="color:#7e7e7e;" v-if="item.constructor_table_title">{{item.constructor_table_title}}</label>
              </div>
              </td>
              <td
                style="text-align: center; cursor: pointer"
                @click="$emit('changePage', i)"
              >
                {{ item.content.length }}
              </td>
              <td style="text-align: center;align-items: center;justify-content: center;">
                <v-row justify="center" align="center">
                  <v-switch color="#6ab64a" hide-details v-model="item.hide" style="margin-left:-5px;"></v-switch>
                </v-row>
              </td>
              <td  style="text-align: center">
                <v-menu offset-y open-on-hover bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      style="cursor: pointer"
                      @click="openEdit(item, i)"
                    >
                      <v-list-item-title
                        >Edit listing group title</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        style="cursor: pointer"
                        @click="$emit('changePage', i)"
                      >
                        Show item list
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-title
                              style="cursor: pointer; color: red"
                              @click="openDeleteDialog(i)"
                      >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </draggable>
          <tbody v-if="items.length === 0">
            <tr>
              <td class="d-block d-sm-table-cell"
                colspan="7"
                style="text-align: center; padding: 0 15px; font-weight: normal"
              >
                You don't have listing group yet, it's time to create them
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <div v-if="localPage === 'edit'">
      <ListingEditCategory
        :edited-category="editedCategory"
        @listingList="listingList"
      />
    </div>
    <PopupDelete
      ref="PopupDeleteCategory"
      :title="`Are your sure listing group ${deleteIndex+1} delete?`"
      @confirm="deleteCategory"
      @saveEdit="saveEdit"
    />
    <PopupSuccess :title="'Thank You!'" :content="successMessage" ref="PopupSuccess" />
  </div>
</template>

<script>
import PopupDelete from "@/components/Popup/PopupDelete";
import PopupSuccess from "@/components/Popup/PopupSuccess";
import draggable from "vuedraggable";
import ListingEditCategory from "@/components/Constructor/Menu/Listing/ListingEditCategory";
import ImagesService from "@/services/images.service.js";
import {mapState} from "vuex"
export default {
  name: "ListingCategories",
  props: ["groups",'item'],
  components: {
    ListingEditCategory,
    PopupDelete,
    PopupSuccess,
    draggable,
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data() {
    return {
      isMobile:false,
      localPage: "table",
      componentKey: 0,
      successMessage: "",
      editIndex: 0,
      deleteIndex: null,
      dialogEditCategory: false,
      headers: [
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Listing count",
          value: "sub_categories",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "hide",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      editedText: "",
      editedCategory: {
        title: "",
      },
    };
  },
  methods: {
    getText(html){
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent
    },
    downloadCSV(item) {
      let data = []
        for (let i = 0; i < item.content.length; i++) {
          let obj = {
            name: item.content[i].name,
            small_description: item.content[i].descr,
            full_description: item.content[i].full_descr,
            main_image: item.content[i].main_img,
            full_images: item.content[i].img.join(","),
            video_link: item.content[i].video_link,
          };
          data.push(obj);
        }
      let csv = this.$papa.unparse(data);
      let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.download =
              this.item.text[this.landing.current_lang].replaceAll(" ", "_") +
              "_" +
              this.$moment().format("DD_MM_yyyy_HH_mm") +
              ".csv";
      link.href = window.URL.createObjectURL(csvData);
      link.dataset.downloadurl = ["text/csv", link.download, link.href].join(
              ":"
      );
      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(evt);
      link.remove();
    },
    async downloadImages(group) {
      let images = [];
        for (
                let index = 0;
                index < group.content.length;
                index++
        ) {
          let item = group.content[index];
          if (item.main_img) {
            let id = this.getImageId(item.main_img);
            if (!images.includes(id)) {
              images.push(id);
            }
          }
          if (item.img.length) {
            for (
                    let imageIndex = 0;
                    imageIndex < item.img.length;
                    imageIndex++
            ) {
              let id = this.getImageId(item.img[imageIndex]);
              if (!images.includes(id)) {
                images.push(id);
              }
            }
          }
      }
      if (images.length) {
        const response = await ImagesService.getZipByImagesID(images);
        if (response.status === 201) {
          const link = document.createElement("a");
          link.href = response.data;
          const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          link.dispatchEvent(evt);
          link.remove();
        }
      } else {
        this.$refs.PopupError.open();
      }
    },
    getImageId(link) {
      return link.split("/")[5];
    },
    openEdit(item, i) {
      this.editedCategory = item;
      this.editIndex = i;
      this.$emit("closeAddGroup");
      this.localPage = "edit";
    },
    listingList() {
      this.$emit("closeAddGroup");
      this.localPage = "table";
    },
    openDeleteDialog(index) {
      this.deleteIndex = index;
      this.$refs.PopupDeleteCategory.open();
    },
    deleteCategory() {
      this.groups.splice(this.deleteIndex, 1);
      this.successMessage = `Listing group # ${
        this.deleteIndex + 1
      } deleted successfully!`;
      this.$refs.PopupSuccess.open();
    },
    openEditCategory(item, index) {
      this.editedCategory = item;
      this.editIndex = index;
      this.dialogEditCategory = true;
    },
    saveEdit(item) {
      this.localPage = "table";
      this.groups.splice(this.editIndex, 1, item);
      this.successMessage = `Listing group # ${
        this.editIndex + 1
      } edited successfully!`;
      this.dialogEditCategory = false;
      this.$refs.PopupSuccess.open();
    },
    closeEditCategory() {
      this.dialogEditCategory = false;
    },
    changeActive(item) {
      item.hide = !item.hide;
    },
  },
  computed:{
    ...mapState(['landing']),
  },
  mounted() {
  }
};
</script>

<style scoped></style>
