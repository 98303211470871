<template>
  <div>
    <div>
      <div v-if="content.styleSettings">
        <div
            style="display: flex; align-items: center; justify-content: space-between;"
        >
          <div>
            <v-btn outlined small color="primary" @click="content.styleSettings = false">
              <v-icon>mdi-chevron-left</v-icon> Back to listing list
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="content.styleSettings" style="margin-bottom:15px;">
        <div style="margin:15px 0;">
          <div>
            <label class="label" style="font-weight:bold;">Global Settings:</label>
          </div>
          <div class="field">
            <label class="label"
            >Choose your listing style</label
            >
            <div class="socials-style">
              <div
                  class="socials-style__item socials-style__item_default socials-style__listing__vertical"
                  style="height: 110px; width: 100px"
                  :class="{
              active: item.styles.displayType === 'default',
            }"
                  @click="changeListingStyle(item, 'default')"
              >
                <img src="@/assets/icons/default.png" alt="" />
              </div>
              <div
                  class="socials-style__item socials-style__item_default socials-style__listing__inside"
                  style="height: 110px; width: 100px"
                  :class="{
              active: item.styles.displayType === 'inside',
            }"
                  @click="changeListingStyle(item, 'inside')"
              >
                <img src="@/assets/icons/inside.png" alt="" />
              </div>
              <div class="socials-style" style="margin: 0">
                <div
                    style="width: 240px"
                    class="socials-style__item socials-style__item_default socials-style__listing__horizontal"
                    :class="{
                active: item.styles.displayType === 'horizontal',
              }"
                    @click="changeListingStyle(item, 'horizontal')"
                >
                  <img src="@/assets/icons/horizontal.png" alt="" />
                </div>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <div><label class="label" style="width:250px;margin-bottom:0;">Listing Vector</label></div>
              <div>
                  <v-radio-group v-model="item.styles.listing_vector" row hide-details>
                    <v-radio label="Vertical" value="vertical" style="margin-right:15px;"></v-radio>
                    <v-radio label="Horizontal" value="horizontal"></v-radio>
                  </v-radio-group>
              </div>
            </div>
            <div v-if="item.styles.displayType === 'inside'"
                 style="display: flex; align-items: center;margin-bottom:15px;"
            >
              <label class="label" style="margin: 0; width: 250px"
              >Position</label
              >
              <v-select
                  style="max-width: 200px;"
                  flat
                  dense
                  outlined
                  hide-details
                  placeholder="Select position"
                  :items="['top', 'center', 'bottom']"
                  class="canvas__select"
                  v-model="content.styles.content_position"
              >
              </v-select>
            </div>
            <ListingBackground :item="item"/>
            <ListingLiningBackground :item="item"/>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div>
            <label class="label" style="font-weight:bold;">Name Settings:</label>
          </div>
          <div class="field">
            <div style="display:flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Font Size
                <b>{{ content.styles.nameFontSize }}</b>
                px</label
              >
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :min="4"
                    :max="30"
                    v-model="content.styles.nameFontSize"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:250px;margin-bottom:0;">
                Font Family
              </label>

              <v-select
                  flat
                  dense
                  outlined
                  hide-details
                  placeholder="Select Font Family"
                  :items="fontFamilyTypes"
                  item-text="label"
                  item-value="value"
                  class="canvas__select"
                  v-model="content.styles.fontFamily"
                  style="max-width:200px;"
              >
                <template v-slot:selection="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div>
            <label class="label" style="font-weight:bold;">Description Settings:</label>
          </div>
          <div class="field">
            <div style="display:flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Font Size
                <b>{{ content.styles.descriptionFontSize }}</b>
                px</label
              >
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :min="4"
                    :max="30"
                    v-model="content.styles.descriptionFontSize"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:250px;margin-bottom:0;">
                Font Family
              </label>
              <v-select
                  flat
                  dense
                  outlined
                  hide-details
                  placeholder="Select Font Family"
                  :items="fontFamilyTypes"
                  item-text="label"
                  item-value="value"
                  class="canvas__select"
                  v-model="content.styles.fontFamilyDescription"
                  style="max-width:200px;"
              >
                <template v-slot:selection="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div v-if="item.styles.displayType === 'inside'" style="margin-bottom:15px;">
          <div>
            <label class="label" style="font-weight:bold;">Lining Text Settings:</label>
          </div>
          <div class="field" >
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Height
                <b>{{ content.styles.lining_height }}</b>
                px</label
              >
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :min="90"
                    :max="300"
                    v-model="content.styles.lining_height"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Width
                <b>{{ content.styles.lining_width }}</b>
                %</label
              >
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :min="10"
                    :max="100"
                    v-model="content.styles.lining_width"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px">
              <div>
                <label class="label" style="width:250px;margin-bottom:0;">
                  Position
                </label>
              </div>
              <div class="control">
                <v-radio-group row v-model="position" hide-details>
                  <v-radio
                      v-for="type in positionTypes.slice(0,3)"
                      :key="type.id"
                      :value="type.valueHorizontal+' '+type.valueVertical"
                      @click="setPosition"
                  >
                  </v-radio>
                </v-radio-group>
                <v-radio-group row v-model="position" hide-details>
                  <v-radio
                      v-for="type in positionTypes.slice(3,6)"
                      :key="type.id"
                      :value="type.valueHorizontal+' '+type.valueVertical"
                      @click="setPosition"
                  >
                  </v-radio>
                </v-radio-group>
                <v-radio-group row v-model="position" hide-details>
                  <v-radio
                      v-for="type in positionTypes.slice(6)"
                      :key="type.id"
                      :value="type.valueHorizontal+' '+type.valueVertical"
                      @click="setPosition"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <div
                style="
            display: flex;
            align-items: center;
            margin-bottom: 15px;
          "
            >
              <label class="label" style="margin-bottom: 0; width: 250px"
              >Background Color
              </label>

              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="show_lining_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="`background-color:${content.styles.lining_color}`"
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.lining_color"
                        @input="show_lining_color = false"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div>
            <label class="label" style="font-weight:bold;">Block Settings:</label>
          </div>
          <div class="field">
            <div style="display:flex;align-items: center;margin-bottom:15px;" v-if="item.styles.listing_vector === 'vertical'">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Height
                <b>{{ content.styles.height }}</b>
                px</label
              >
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :min="50"
                    :max="500"
                    v-model="content.styles.height"
                ></v-slider>
              </div>
            </div>
            <div  v-if="item.styles.listing_vector === 'horizontal'" style="display:flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Width
                <b>{{ content.styles.width }}</b>
                px</label
              >
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :min="50"
                    :max="400"
                    v-model="content.styles.width"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Border Radius
                <b>{{ item.styles.borderListingRadius }}</b>
                px</label
              >
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :min="0"
                    :max="50"
                    v-model="item.styles.borderListingRadius"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Border Size
                <b>{{ content.styles.border_size }}</b>
                px</label
              >
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :min="0"
                    :max="50"
                    v-model="content.styles.border_size"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <div>
                <label
                    class="label"
                    style="margin-bottom: 0;width:250px;"
                >
                  Border color
                </label>
              </div>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_listing_border_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${content.styles.border_color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker v-model="content.styles.border_color" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display:flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Shadow Size
                <b>{{ content.styles.shadow_size }}</b>
                px</label
              >
              <div class="slider" style="width:100%;">
                <v-slider
                    hide-details
                    :min="0"
                    :max="50"
                    v-model="content.styles.shadow_size"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label
                  class="label"
                  style="margin-bottom: 0;width:250px;"
              >
                Shadow color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_listing_shadow_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${content.styles.shadow_color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker v-model="content.styles.shadow_color" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div>
            <label class="label" style="font-weight:bold;">Image Settings:</label>
          </div>
          <div class="field">
            <div style="display:flex;align-items:center;margin-bottom:15px;" v-if="item.styles.displayType !== 'inside'">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >List Height
                <b>{{ item.styles.listing_img_height }}</b>
                px</label
              >
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :min="90"
                    :max="300"
                    v-model="item.styles.listing_img_height"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;" v-if="item.styles.displayType === 'horizontal'">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Width
                <b>{{ item.styles.listing_img_width }}</b>
                px</label
              >
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :min="50"
                    :max="123"
                    v-model="item.styles.listing_img_width"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="min-width:242px;margin-bottom:0;"
              >Detail Height
                <b>{{ item.styles.detail_listing_img_height }}</b>
                px</label
              >
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :min="90"
                    :max="300"
                    v-model="item.styles.detail_listing_img_height"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!content.listingSettings&&!content.styleSettings">
        <div v-if="localPage === 'group' && open ">
          <div
              style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <label class="label" style="float: left"
            >Show categories</label
            >
            <v-switch color="#6ab64a"
                v-model="content.show_constructor_categories"
                      @change="setMenu"
            ></v-switch>
          </div>
          <!--HORIZONTAL --->
          <div
              v-if="content.show_constructor_categories"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <label class="label" style="float: left"
              >Show horizontal menu</label
            >
            <v-switch color="#6ab64a" v-model="item.horizontal" @change="setMenu"></v-switch>
          </div>
          <div v-if="item.horizontal&&content.show_constructor_categories">
            <div
              style="
                display: flex;
                align-items: center;
                margin: 10px 0;
                justify-content: space-between;
              "
            >
              <label
                class="label"
                style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Background color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_bg"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    :style="`background-color:${item.styles.horizontal_background}`"
                    style="margin-right: 10px"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="item.styles.horizontal_background"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                margin: 10px 0;
                justify-content: space-between;
              "
            >
              <label
                class="label"
                style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Background active color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_bg_active"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    style="margin-right: 10px"
                    :style="`background-color:${item.styles.horizontal_active_background}`"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="item.styles.horizontal_active_background"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                margin: 10px 0;
                justify-content: space-between;
              "
            >
              <label
                class="label"
                style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Text color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="colorpicker"
                    style="margin-right: 10px"
                    :style="`background-color:${item.styles.horizontal_color}`"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="item.styles.horizontal_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                margin: 10px 0;
                justify-content: space-between;
              "
            >
              <label
                class="label"
                style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Active text color</label
              >
              <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_payment_active_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                    style="margin-right: 10px"
                    class="colorpicker"
                    :style="`background-color:${item.styles.horizontal_active_color}`"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="item.styles.horizontal_active_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
          <!--HORIZONTAL --->

          <!--MOTIVATION --->
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <label class="label" style="float: left"
              >Show motivation text</label
            >
            <v-switch color="#6ab64a"
              v-model="item.motivation.show"
              @change="setMenu"
            ></v-switch>
          </div>
          <div
            class="control"
            v-if="item.motivation.show"
            style="margin-bottom: 30px"
          >
            <label class="label">Change motivation text</label>
            <div class="control">
              <Editor
                v-model="item.motivation.content"
                api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                :init="options"
                @change="setMenu"
              ></Editor>
            </div>
          </div>
          <!--MOTIVATION --->

          <!--BUTTONS--->
          <div
            v-if="content.show_constructor_categories"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <v-btn
              class="mb-4"
              color="primary"
              depressed
              outlined
              small
              @click="addListingGroup(content.content.listing)"
            >
              + Add listing group
            </v-btn>
          </div>
          <!--BUTTONS--->
        </div>
        <div v-if="localPage === 'group' && content.show_constructor_categories">
          <ListingCategories
            :groups="content.content.listing"
            @changePage="changePage"
            @closeAddGroup="closeAddGroup"
            :item="item"
            @editItemGroup="editItemGroup"
          >
            <div
                style="display: flex; align-items: center; justify-content: space-between;"
            >
              <div v-if="content.styleSettings">
                <v-btn outlined small color="primary" @click="content.styleSettings = false">
                  <v-icon>mdi-chevron-left</v-icon> Back to listing list
                </v-btn>
              </div>
              <div v-else></div>
              <div>

              </div>
            </div>
          </ListingCategories>
          <SliderMenu
            v-if="open"
            style="margin: 50px 0 30px 0"
            :slider_menu="content.slider"
            :active_slider="content"
            :menu="menu"
          />
        </div>
        <div v-if="localPage === 'listings' && !selectedCategory || localPage === 'group' && !content.show_constructor_categories" >
          <div
              style="display: flex; align-items: center; justify-content: space-between;"
          >
            <div v-if="content.styleSettings">
              <v-btn outlined small color="primary" @click="content.styleSettings = false">
                <v-icon>mdi-chevron-left</v-icon> Back to listing list
              </v-btn>
            </div>
            <v-btn
                style="
          margin-left: auto;
          margin-right: 10px;
          margin-top: 15px;
          display: flex;
        "
                class="mb-4"
                color="primary"
                depressed
                outlined
                small
                @click="addListing(content.content.listing[0].content)"
            >
              + Add listing item
            </v-btn>
          </div>
          <div v-for="(listingGroup,index) in content.content.listing" :key="`group_${index}`">
            <ListingList
              :listings="content.content.listing[index].content"
              @groupOpen="groupOpen"
              @editItem="editItem"
              :content="content"
              :selectedGroupIndex="index"
            >
            </ListingList>
          </div>
        </div>
        <div v-if="localPage === 'listings' && selectedCategory">
          <ListingList
              :listings="selectedCategory.content"
              @groupOpen="groupOpen"
              @editItem="editItem"
              :content="content"
              :selectedGroupIndex="selectedGroupIndex"
          >
          </ListingList>
        </div>
        <div v-if="localPage === 'detail'">
          <ListingDetail
            :content="content"
            :listing="
              content.content.listing[selectedGroupIndex].content[
                selectedItemIndex
              ]
            "
            :menuType="menuType"
            :groupIndex="selectedGroupIndex"
            :listingIndex="selectedItemIndex"
            :menu="menu"
            @listingList="listingList"
            :item="item"
          >
          </ListingDetail>
        </div>
      </div>
    </div>

    <v-dialog :fullscreen="$store.state.isMobile" v-model="item.outsideData" max-width="700px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="item.outsideData = false"
          >mdi-close</v-icon
        >
        <v-card-title> Use Outside Data </v-card-title>
        <v-card-text>
          <div
            class="field"
            style="margin-bottom: 15px; display: flex; flex-direction: column"
            v-if="item.outsideData"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
              "
            >
              <label class="label" style="float: left">Data count</label>
              <v-text-field
                type="number"
                min="0"
                outlined
                dense
                hide-details
                style="max-width: 300px"
                v-model="item.outsideCount"
                @change="setMenu"
              ></v-text-field>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
              "
            >
              <label class="label" style="float: left">Data link</label>
              <v-text-field
                outlined
                dense
                hide-details
                style="max-width: 300px"
                v-model="item.outsideLink"
                @change="setMenu"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 15px; display: flex; justify-content: flex-end"
        >
          <v-btn
            @click="setDataFromOutside(item, content.content)"
            color="primary"
            outlined
            dense
            small
            style="max-width: 100px; align-self: flex-end"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadCSV" max-width="800px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeUploadCSV"
          >mdi-close</v-icon
        >
        <v-card-title>Upload csv data</v-card-title>
        <v-card-text>
          <div>
            <label class="label" style="margin: 15px 0; display: block"
              >File uploader </label
            >
          </div>
          <div>
            <v-btn
              color="primary"
              outlined
              small
              :loading="isSelecting"
              @click="handleFileImport"
            >
              Upload File
            </v-btn>
            {{ selectedFile ? selectedFile.name : "" }}
          </div>
          <v-file-input
            accept="text/csv"
            show-size
            dense
            prepend-icon=""
            ref="uploader"
            class="d-none"
            outlined
            @change="onFileChange"
          ></v-file-input>
            <div style="max-width: 500px;margin-top:15px;">
                To add display buttons on the detail page of the listing, use: <br>
                [button=https:/tourist.com]Tourist[/button]<br>

                To add an active link, use<br>
                [url=https://tourist.com]Tourist[/url]<br><br>

                We have prepared for you a sample csv table, which you can <a href="https://tourist.hr">get here</a>
            </div>
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 15px; display: flex; justify-content: flex-end"
        >
          <v-btn
            @click="openUploadImageDialog"
            color="primary"
            outlined
            dense
            small
            style="max-width: 100px; align-self: flex-end"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadImage" max-width="800px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeUploadImageDialog"
          >mdi-close</v-icon
        >
        <v-card-title>Upload images</v-card-title>
        <v-card-text>
            <label class="label" style="display: block;margin-bottom:15px;">We accepted .jpg .png .gif .svg images type files</label><br>
            <label class="label" style="display: block;margin-bottom:15px;">We found in your file items of listing: {{listingCount}}</label>
          <vue-dropzone
            :id="`uploadCSV`"
            :ref="`uploadCSV`"
            :options="dropzoneOptionsUploadCSV"
            style="margin-bottom: 15px"
            maxFiles="100"
            :destroyDropzone="false"
            @vdropzone-sending="customEventUploadCSV"
            @vdropzone-success="handleSuccessUploadCSV"
          >
          </vue-dropzone>
            <label class="label" style="display: block;margin-bottom:15px;">We uploaded {{countOfImages-uploadedImages.length}} of {{countOfImages}} images.</label>
            <label class="label" style="display: block;margin-bottom:15px;">We are waiting for pictures with names:</label>
          <div
            v-for="img in uploadedImages"
            :key="img+'uploaded'"
            style="margin-right: 15px"
          >
            {{ img }}
          </div>
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 15px; display: flex; justify-content: flex-end"
        >
          <v-btn
            @click="completeUploadCSV"
            color="primary"
            outlined
            dense
            small
            style="max-width: 100px; align-self: flex-end"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PopupConfirm
      ref="PopupConfirm"
      @confirm="confirmCopy"
      :title="`Are you sure about copy content from ${defaultLangName} to ${currentLangName}`"
    />
    <PopupDelete
      ref="PopupDelete"
      @confirm="deleteListingGroupConfirm"
      title="Are you sure about delete listing group?"
    />
    <PopupDelete
      ref="PopupDeleteListing"
      title="Are you sure about delete listing?"
      @confirm="deleteListingConfirm"
    />
    <PopupError ref="PopupError" title="You don't have any images here" />
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogEditCategory"
      max-width="710"
      :retain-focus="true"
      @click:outside="closeEditCategory"
    >
    </v-dialog>
  </div>
</template>

<script>
import SliderMenu from "./SliderMenu";
import { mapState } from "vuex";
import "../../../../node_modules/cropperjs/dist/cropper.min.css";
import MediaService, { entities } from "../../../services/media.service";
import vueDropzone from "vue2-dropzone";
import PopupConfirm from "@/components/Popup/PopupConfirm.vue";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import defaults, { defaultFormMenuItem } from "@/store/landing.js";
import ListingCategories from "@/components/Constructor/Menu/Listing/ListingCategories";
import ListingList from "@/components/Constructor/Menu/Listing/ListingList";
import ListingDetail from "@/components/Constructor/Menu/Listing/ListingDetail";
import { defaultContentMenuItem } from "@/store/landing.js";
import PopupError from "@/components/Popup/PopupError";
import ImagesService from "@/services/images.service";
import ListingBackground from "@/components/Constructor/Menu/Listing/ListingBackground";
import ListingLiningBackground from "@/components/Constructor/Menu/Listing/ListingLiningBackground";
export default {
  name: "typeListing",
  components: {
    ListingLiningBackground,
    ListingBackground,
    PopupError,
    ListingDetail,
    ListingList,
    ListingCategories,
    // FormOrder,
    SliderMenu,
    vueDropzone,
    // draggable,
    PopupConfirm,
    PopupDelete,
  },
  data() {
    return {
      countOfImages:0,
      open: true,
      position:"center center",
      show_listing_border_color:false,
      show_listing_shadow_color:false,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-favicon";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 0.2,
        maxFiles: 1,
        destroyDropzone: false,
      },
      selectedCategory:null,
      dialogEditCategory: false,
      editedCategory: null,
      localPage: "group",
      componentKey: 0,
      dropzoneOptionsUploadCSV: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 300,
        destroyDropzone: false,
      },
      uploadCSV: false,
      uploadImage: false,
      showOutSideData: false,
      show_lining_color: false,
      deleteListingID: null,
      uploadedImages: [],
      isSelecting: false,
      selectedFile: null,
        uploadedData: [],
      positionTypes: [
        { id: 0, valueVertical: "flex-start", valueHorizontal: "flex-start" },
        { id: 1, valueVertical: "flex-start", valueHorizontal: "center" },
        { id: 2, valueVertical: "flex-start", valueHorizontal: "flex-end" },
        { id: 3, valueVertical: "center", valueHorizontal: "flex-start" },
        { id: 4, valueVertical: "center", valueHorizontal: "center" },
        { id: 5, valueVertical: "center", valueHorizontal: "flex-end" },
        { id: 6, valueVertical: "flex-end", valueHorizontal: "flex-start" },
        { id: 7, valueVertical: "flex-end", valueHorizontal: "center" },
        { id: 8, valueVertical: "flex-end", valueHorizontal: "flex-end" },
      ],
      fontFamilyTypes: [
        { id: 0, value: "Arial", label: "Arial" },
        { id: 1, value: "SF UI Text", label: "SF UI Text" },
        { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
        { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
        { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
        { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
        { id: 6, value: "Geometria", label: "Geometria" },
        { id: 7, value: "DIN Pro", label: "DIN Pro" },
        { id: 8, value: "Circe", label: "Circe" },
        { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
        { id: 10, value: "Acrom", label: "Acrom" },
        { id: 11, value: "Inter", label: "Inter" },
      ],
      deleteListingGroupID: null,
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: `a[title=button] {
          max-width: 360px;
          height: 40px;
          min-width: 71px;
          padding: 0 17.7777777778px;
          align-items: center;
          border-radius: 5px;
          display: inline-flex;
          flex: 0 0 auto;
          font-weight: 500;
          letter-spacing: normal;
          justify-content: center;
          outline: 0;
          position: relative;
          text-decoration: none;
          text-indent: normal;
          text-transform: none;
          transition-duration: 0.28s;
          transition-property: box-shadow, transform, opacity;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          vertical-align: middle;
          white-space: nowrap;
        }`,
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      show_payment_color: false,
      show_payment_active_color: false,
      show_payment_bg: false,
      show_payment_bg_active: false,
      deleteGroupIndex: null,
      uploadDataIndexes: [],
      uploadDataType: "main_img",
      selectedGroupIndex: null,
      selectedItemIndex: null,
        listingCount:0,
      selectedGroup: {
        name: `Listing group # 1`,
        index: 0,
      },
        removedImages:[]
    };
  },
  computed: {
    ...mapState("landing", ["menu", "settings_id"]),
    ...mapState(["landing"]),
    entities() {
      return entities;
    },
    currentLangName() {
      return this.landing.advanced.langs.find(
        (x) => x.value === this.landing.current_lang
      ).name;
    },
    defaultLangName() {
      return this.landing.advanced.langs.find(
        (x) => x.value === this.landing.advanced.default_lang
      ).name;
    },
    groups() {
      let names = [];
      for (let i = 0; i < this.content.content.listing.length; i++) {
        let obj = {
          name: `Listing group # ${i + 1}`,
          index: i,
        };
        names.push(obj);
      }
      return names;
    },
  },
  mounted() {},
  props: ["item", "content", "menuType"],
  methods: {
    addListing(arr) {
      let listingDefault = {
        img: [],
        video_link: null,
        name: "default name",
        descr: "default description",
        main_img: "",
        preview: false,
        full_descr: "default full description",
        price: "100",
        show_price: true,
        show_descr: true,
        name_color: "#000000",position:"center center",background_color:"rgba(255,255,255,1)",
        hide: true,
        descr_color: "#000000",
        show_name_color: false,
        show_descr_color: false,
        id: 1,
        form: JSON.parse(JSON.stringify(defaults.defaultFormMenuItem)),
      };
      if (arr.length) {
        listingDefault.id = arr[arr.length - 1].id + 1;
      } else {
        listingDefault.id = 1;
      }
      this.listingGroupItemIndex = arr.length - 1;
      arr.push(listingDefault);
    },
    setPosition() {
      this.content.styles.position = this.position;
    },
    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      // Trigger click on the FileInput
      this.$refs.uploader.$refs.input.click();
    },
    async downloadImages() {
      let images = [];
      for (let i = 0; i < this.content.content.listing.length; i++) {
        for (
          let index = 0;
          index < this.content.content.listing[i].content.length;
          index++
        ) {
          let item = this.content.content.listing[i].content[index];
          if (item.main_img) {
            let id = this.getImageId(item.main_img);
            if (!images.includes(id)) {
              images.push(id);
            }
          }
          if (item.img.length) {
            for (
              let imageIndex = 0;
              imageIndex < item.img.length;
              imageIndex++
            ) {
              let id = this.getImageId(item.img[imageIndex]);
              if (!images.includes(id)) {
                images.push(id);
              }
            }
          }
        }
      }
      if (images.length) {
        const response = await ImagesService.getZipByImagesID(images);
        if (response.status === 201) {
          const link = document.createElement("a");
          link.href = response.data;
          const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          link.dispatchEvent(evt);
          link.remove();
        }
      } else {
        this.$refs.PopupError.open();
      }
    },
    getImageId(link) {
      return link.split("/")[5];
    },
    closeAddGroup() {
      this.open = !this.open;
    },
    closeEditCategory() {
      this.dialogEditCategory = false;
    },
    editItemGroup(index) {
      this.editedCategory = JSON.parse(
        JSON.stringify(this.content.content.listing[index])
      );
      this.selectedGroupIndex = index;
      this.dialogEditCategory = true;
    },
    listingList() {
      if(!this.content.show_constructor_categories){
        this.selectedCategory = null;
        this.localPage = "group";
      }else{
        this.localPage = "listings";
      }
      this.selectedItemIndex = null;
      if (this.menuType === "submenu") {
          this.$store.state.listing.listingGroup = null;
          this.$store.state.listing.subListing = null;
      } else {
        this.$store.commit("setPage", this.item.page);
        this.$store.state.listing.listingGroup = null;
        this.$store.state.listing.subListing = null;
      }
    },
    editItem(data) {
      this.selectedGroupIndex = data.group;
      this.selectedItemIndex = data.item;
      if (this.menuType === "submenu") {
          this.$store.state.listing.listingGroup = this.selectedGroupIndex
          this.$store.state.listing.subListing = this.selectedItemIndex;
      } else {
          this.changeListingPage(this.item.page, this.selectedGroupIndex, this.selectedItemIndex);
      }
      this.localPage = "detail";
    },
    groupOpen() {
      this.selectedCategory = null;
      this.localPage = "group";
    },
    changePage(index) {
      this.selectedCategory = this.content.content.listing[index];
      this.selectedGroupIndex = index;
      this.localPage = "listings";
    },
    downloadCSV() {
      const data = this.content.content.listing.reduce((acc, item, index) => {
        for (let i = 0; i < item.content.length; i++) {
          let obj = {
            name: item.content[i].name,
            small_description: item.content[i].descr,
            full_description: item.content[i].full_descr,
            main_image: item.content[i].main_img,
            full_images: item.content[i].img.join(","),
            video_link: item.content[i].video_link,
            title:item.name?item.name:'listing group #'+index
          };
          acc.push(obj);
        }
        return acc;
      }, []);
      let csv = this.$papa.unparse(data);
      let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.download =
        this.item.text[this.landing.current_lang].replaceAll(" ", "_") +
        "_" +
        this.$moment().format("DD_MM_yyyy_HH_mm") +
        ".csv";
      link.href = window.URL.createObjectURL(csvData);
      link.dataset.downloadurl = ["text/csv", link.download, link.href].join(
        ":"
      );
      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(evt);
      link.remove();
    },
    completeUploadCSV() {
      for (let i = 0; i < this.uploadedData.length; i++) {
        for (
          let index = 0;
          index < this.uploadedData[i].content.length;
          index++
        ) {
          if (
            !this.uploadedData[i].content[index].main_img.includes("guesteco.com")
          ) {
            this.uploadedData[i].content[index].main_img = "";
          }
          let imgs = [];
          for (
            let index1 = 0;
            index1 < this.uploadedData[i].content[index].img.length;
            index1++
          ) {
            if (
              this.uploadedData[i].content[index].img[index1].includes(
                "guesteco.com"
              )
            ) {
              imgs.push(this.uploadedData[i].content[index].img[index1]);
            }
          }
          this.uploadedData[i].content[index].img = JSON.parse(
            JSON.stringify(imgs)
          );
        }
      }
      this.content.content.listing = [
        ...this.content.content.listing,
        ...this.uploadedData,
      ];
      this.uploadedData = [];
      this.uploadImage = false;
    },
    openUploadImageDialog() {
      this.uploadImage = true;
      this.uploadCSV = false;
    },
    closeUploadImageDialog() {
      this.uploadImage = false;
    },
    openUploadCSV() {
      this.uploadedData = [];
      this.uploadCSV = true;
    },
    closeUploadCSV() {
      this.uploadCSV = false;
    },
    hideListingContent(listing) {
      if (listing.hide) {
        listing.hide = false;
      } else {
        listing.hide = true;
      }
    },
    openCopy() {
      this.$refs.PopupConfirm.open();
    },
    confirmCopy() {
      let defaultContent = JSON.parse(
        JSON.stringify(this.item.content[this.landing.advanced.default_lang][3])
      );
      this.item.content[this.landing.current_lang][3].content =
        defaultContent.content;
      this.item.content[this.landing.current_lang][3].active_slider =
        defaultContent.active_slider;
      this.item.content[this.landing.current_lang][3].slider =
        defaultContent.slider;
      this.setMenu();
    },
    hideListing(item) {
      item.hide = !item.hide;
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    changeListingStyle(item, style) {
      item.styles.displayType = style;
      this.setMenu();
    },
    strip(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText;
    },
    async deleteListing(groupIndex, listingIndex) {
      this.deleteListingGroupID = groupIndex;
      this.deleteListingID = listingIndex;
      this.$refs.PopupDeleteListing.open();
    },
    deleteListingConfirm() {
      this.content.content.listing[this.deleteListingGroupID].content.splice(
        this.deleteListingID,
        1
      );
      this.setMenu();
      this.deleteListingGroupID = null;
      this.deleteListingID = null;
    },
    deleteSubListing(listing, i) {
      listing.splice(i, 1);
      this.setMenu();
    },
    async setDataFromOutside(menu, listing) {
      let link = menu.outsideLink;

      if (menu.outsideCount) {
        link += "&count=" + menu.outsideCount;
      }
      const response = await this.$axios.get(link);
      listing.listing[0].content = [];
      for (let i = 0; i < response.data.length; i++) {
        let item = {
          img: [],
          video_link: null,
          name: "default name",
          descr: "default description",
          main_img: "",
          preview: false,
          full_descr: "default full description",
          price: "100",
          show_price: true,
          show_descr: true,
          name_color: "#000000",
          position:"center center",
          background_color:"rgba(255,255,255,1)",
          descr_color: "#000000",
          show_name_color: false,
          show_descr_color: false,
          id: 1,
          form: {
            input_1: null,
            input_2: null,
            input_3: null,
            textarea: null,
            email: null,
            btn_name: "Send",
            btn_color: "#000000",
            btn_bg: "rgba(255,255,255,0.5)",
            btn_border: "#000000",
            show: false,
            type: "pro",
            flip: false,
            pro: [
              {
                id: 0,
                title: "default title",
                addItem: false,
                formDialog: false,
                content: [
                  {
                    id: 0,
                    radio: "checkbox",
                    label: "default label",
                    count: 1,
                    price: "0",
                    text: "",
                    formDialogEdit: false,
                  },
                ],
              },
            ],
          },
        };
        if (response.data[i].images) {
          item.img = response.data[i].images;
        }
        if (response.data[i].image) {
          item.main_img = response.data[i].image;
        }
        if (response.data[i].content) {
          response.data[i].content = response.data[i].content.replace(
            "font-family: Arial;",
            ""
          );
          let text = new DOMParser()
            .parseFromString(response.data[i].content, "text/xml")
            .getElementsByTagName("p")[0]
            .getElementsByTagName("span")[0]
            .getAttribute("data-sheets-value");
          item.descr = text.slice(12, 100) + "...";
          item.full_descr = response.data[i].content;
          item.full_descr += `<a href=${response.data[i].link_to_detail} target="_blank">Open Tourist</a>`;
        }

        if (response.data[i].title) {
          item.name = response.data[i].title;
        }
        item.id = i;
        listing.listing[0].content.push(item);
      }
    },
    deleteListingGroup(i) {
      this.deleteGroupIndex = i;
      this.$refs.PopupDelete.open();
    },
    deleteListingGroupConfirm() {
      this.content.content.listing.splice(this.deleteGroupIndex, 1);
      this.deleteGroupIndex = null;
      this.setMenu();
    },
    mountDropzoneMain() {
      for (let i = 0; i < this.content.content.listing.length; i++) {
        for (
          let index = 0;
          index < this.content.content.listing[i].content.length;
          index++
        ) {
          let item = this.content.content.listing[i].content[index];
          if (item.main_img) {
            let mockFile = {
              accepted: true,
              cropped: true,
              kind: "image",
              ...item,
            };
            if (
              this.$refs[`upload_${i}_${index}`][0].dropzone &&
              this.$refs[`upload_${i}_${index}`][0].dropzone.files.length === 0
            ) {
              this.$refs[`upload_${i}_${index}`][0].manuallyAddFile(
                mockFile,
                item.main_img
              );
              this.$refs[`upload_${i}_${index}`][0].dropzone[
                "previewsContainer"
              ].children[1].children[0].children[0].src = item.main_img;
              this.$refs[`upload_${i}_${index}`][0].dropzone[
                "previewsContainer"
              ].children[1].children[0].style.borderRadius = 0;
              this.$refs[`upload_${i}_${index}`][0].dropzone[
                "previewsContainer"
              ].children[1].children[1].style.background = "none";
              this.$refs[`upload_${i}_${index}`][0].dropzone.emit(
                "complete",
                mockFile
              );
            }
          }
        }
      }
    },
    mountDropzone() {
      for (let i = 0; i < this.content.content.listing.length; i++) {
        for (
          let index = 0;
          index < this.content.content.listing[i].content.length;
          index++
        ) {
          let item = this.content.content.listing[i].content[index];

          for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
            let mockFile = {
              accepted: true,
              cropped: true,
              imgRemove: imgInd,
              kind: "image",
              ...item,
            };
            if (this.$refs[`upload_detail_${i}_${index}`][0].dropzone) {
              if (
                !this.$refs[
                  `upload_detail_${i}_${index}`
                ][0].dropzone.files.includes(item.img[imgInd])
              ) {
                if (
                  this.$refs[`upload_detail_${i}_${index}`][0].dropzone.files
                    .length !== item.img.length
                ) {
                  this.$refs[`upload_detail_${i}_${index}`][0].manuallyAddFile(
                    mockFile,
                    item.img[imgInd]
                  );
                  this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                  ].children[imgInd + 1].children[0].children[0].src =
                    item.img[imgInd];
                  this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                  ].children[imgInd + 1].children[0].style.borderRadius = 0;
                  this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                  ].children[imgInd + 1].children[1].style.background = "none";
                  this.$refs[`upload_detail_${i}_${index}`][0].dropzone.emit(
                    "complete",
                    mockFile
                  );
                }
              }
            }
          }
        }
      }
    },
    async onFileChange(file) {
      this.selectedFile = file;
      this.uploadedImages = [];
      this.$papa.parse(file, {
        complete: (result) => {
            this.listingCount = result.data.length-1;
            for (let i = 1; i < result.data.length; i++) {
            if (result.data[i][6]) {
                const descrArr = result.data[i][2].split('[/button]');
                const descrButtons = descrArr.reduce((acc,item) => {
                    if(item.includes('[button')){
                        item+='[/button]';
                        item = item.replace(/\[button=([^\s\]]+)\s*\](.*(?=\[\/button\]))\[\/button\]/g, '<a class="v-btn v-btn--outlined theme--light v-size--small primary--text" target="_blank" href="$1">$2</a>');
                        acc += item;
                    }else{
                        acc+=item;
                    }

                    return acc;
                }, "");
                const urlArr = descrButtons.split('[/url]');
                const full_descr = urlArr.reduce((acc,item) => {
                    if(item.includes('[url')){
                        item+='[/url]';
                        item = item.replace(/\[url=([^\s\]]+)\s*\](.*(?=\[\/url\]))\[\/url\]/g, '<a  target="_blank" href="$1">$2</a>');
                        acc += item;
                    }else{
                        acc+=item;
                    }
                    return acc;
                }, "");

                let imgs = result.data[i][4].split(",").reduce((acc, item) => {
                    if(item){
                        acc.push(this.normalizeImg(item));
                    }
                return acc;
              }, []);
                const candidate = this.uploadedData.find(
                  (x) => x.title === result.data[i][6]
                );
              if (candidate) {
                let listingItem = {
                  img: imgs,
                  video_link: result.data[i][5],
                  name: result.data[i][0],
                  descr: result.data[i][1],
                  main_img: this.normalizeImg(result.data[i][3]),
                  preview: false,
                  full_descr: `<p>${full_descr}</p>`,
                  price: "100",
                  show_price: true,
                  hide: true,
                  show_descr: true,
                  name_color: "#000000",position:"center center",background_color:"rgba(255,255,255,1)",
                  descr_color: "#000000",
                  show_name_color: false,
                  show_descr_color: false,
                  id: candidate.length
                    ? candidate[candidate.length - 1].id + 1
                    : 10000,
                  form: JSON.parse(
                    JSON.stringify(defaults.defaultFormMenuItem)
                  ),
                };
                if (
                  listingItem.main_img &&
                  !this.uploadedImages.includes(this.normalizeImg(listingItem.main_img)) &&
                  !listingItem.main_img.includes("guesteco.com")
                ) {
                  this.uploadedImages.push(this.normalizeImg(listingItem.main_img));
                }
                listingItem.img.forEach((img) => {
                  if (
                    !this.uploadedImages.includes(this.normalizeImg(img)) &&
                    !img.includes("guesteco.com")
                  ) {
                    this.uploadedImages.push(this.normalizeImg(img));
                  }
                });
                this.uploadedData[
                  this.uploadedData.indexOf(candidate)
                ].content.push(listingItem);
              } else {
                let listingGroup = {
                  title: result.data[i][6],
                  content: [
                    {
                      img: imgs,
                      video_link: result.data[i][5],
                      name: result.data[i][0],
                      descr: result.data[i][1],
                      main_img: this.normalizeImg(result.data[i][3]),
                      preview: false,
                      full_descr: `<p>${full_descr}</p>`,
                      price: "100",
                      show_price: true,
                      hide: true,
                      show_descr: true,
                      name_color: "#000000",position:"center center",background_color:"rgba(255,255,255,1)",
                      descr_color: "#000000",
                      show_name_color: false,
                      show_descr_color: false,
                      id: 0,
                      form: JSON.parse(
                        JSON.stringify(defaults.defaultFormMenuItem)
                      ),
                    },
                  ],
                  hide: true,
                };
                if (
                  listingGroup.content[0].main_img &&
                  !this.uploadedImages.includes(
                      this.normalizeImg(listingGroup.content[0].main_img)
                  ) &&
                  !listingGroup.content[0].main_img.includes("guesteco.com")
                ) {
                  this.uploadedImages.push(this.normalizeImg(listingGroup.content[0].main_img));
                }
                listingGroup.content[0].img.forEach((img) => {
                  if (
                    !this.uploadedImages.includes(this.normalizeImg(img)) &&
                    !img.includes("guesteco.com")
                  ) {
                    this.uploadedImages.push(this.normalizeImg(img));
                  }
                });
                this.uploadedData.push(listingGroup);
              }
            }
          }
            this.countOfImages = this.uploadedImages.length;
        },
      });
    },
      normalizeImg(img){
        if(img){
          if(img.includes('guesteco.com')){
            return img;
          }else{
            return img.replaceAll(' ','').replaceAll('&','').replaceAll('-','').toLowerCase().replaceAll('jpeg','jpg').replaceAll('š','s').replaceAll('ć','c').replaceAll('š','s').replaceAll('ć','c');
          }
        }else{
            return ""
        }
      },
    handleSuccessUploadCSV(file) {
      let trigger = true;
      for (let i = 0; i < this.uploadedData.length; i++) {
        for (
          let index = 0;
          index < this.uploadedData[i].content.length;
          index++
        ) {
          if (this.uploadedData[i].content[index].main_img === this.normalizeImg(file.name)) {
            this.uploadedData[i].content[index].main_img = file.xhr.response;
            if (this.uploadedImages.includes(this.normalizeImg(file.name))) {
              this.uploadedImages.splice(
                this.uploadedImages.indexOf(this.normalizeImg(file.name)),
                1
              );
            }
              trigger = false;
          }
          if (this.uploadedData[i].content[index].img.includes(this.normalizeImg(file.name))) {
            this.uploadedData[i].content[index].img[
              this.uploadedData[i].content[index].img.indexOf(this.normalizeImg(file.name))
            ] = file.xhr.response;
            if (this.uploadedImages.includes(this.normalizeImg(file.name))) {
              this.uploadedImages.splice(
                this.uploadedImages.indexOf(this.normalizeImg(file.name)),
                1
              );
            }
            trigger = false;
          }
        }
          if (i === this.uploadedData.length - 1 && trigger) {
              this.removedImages.push(this.normalizeImg(file.name));
              this.$refs.uploadCSV.removeFile(file);
          }
      }
    },
    customEventUploadCSV(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    async handleRemove(file, i, listingIndex) {
      if (file.imgRemove || file.imgRemove === 0) {
        this.content.content.listing[i].content[listingIndex].img.splice(
          file.imgRemove,
          1
        );
        let item = this.content.content.listing[i].content[listingIndex];
        let elements =
          this.$refs[
            `upload_detail_${i}_${listingIndex}`
          ][0].$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        this.$refs[`upload_detail_${i}_${listingIndex}`][0].dropzone.files = [];
        for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
          let mockFile = {
            accepted: true,
            cropped: true,
            imgRemove: imgInd,
            notRemove: true,
            kind: "image",
            ...item,
          };
          if (this.$refs[`upload_detail_${i}_${listingIndex}`][0].dropzone) {
            if (
              !this.$refs[
                `upload_detail_${i}_${listingIndex}`
              ][0].dropzone.files.includes(item.img[imgInd])
            ) {
              if (
                this.$refs[`upload_detail_${i}_${listingIndex}`][0].dropzone
                  .files.length !== item.img.length
              ) {
                this.$refs[
                  `upload_detail_${i}_${listingIndex}`
                ][0].manuallyAddFile(mockFile, item.img[imgInd]);
                this.$refs[`upload_detail_${i}_${listingIndex}`][0].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].children[0].src =
                  item.img[imgInd];
                this.$refs[`upload_detail_${i}_${listingIndex}`][0].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].style.borderRadius = 0;
                this.$refs[`upload_detail_${i}_${listingIndex}`][0].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[1].style.background = "none";
                this.$refs[
                  `upload_detail_${i}_${listingIndex}`
                ][0].dropzone.emit("complete", mockFile);
              }
            }
          }
        }
      }
      this.setMenu();
    },
    hideSmallDescription(listing) {
      listing.show_descr = !listing.show_descr;
      this.setMenu();
    },
    addListingGroup(arr) {
      let defaultListingGroup = {
        title: "default listing title",
        hide: true,
        content: [
          {
            img: [],
            video_link: null,
            name: "default name",
            descr: "default description",
            main_img: "",
            preview: false,
            full_descr: "default full description",
            price: "100",
            hide: true,
            show_price: true,
            show_descr: true,
            name_color: "#000000",position:"center center",background_color:"rgba(255,255,255,1)",
            descr_color: "#000000",
            show_name_color: false,
            show_descr_color: false,
            id: 1,
            form: JSON.parse(JSON.stringify(defaults.defaultFormMenuItem)),
          },
        ],
      };
      arr.push(defaultListingGroup);
      this.listingGroupIndex = arr.length - 1;
      this.listingGroupItemIndex = 0;
      this.setMenu();
    },
    setMenu() {
      this.$store.commit("landing/setMenu", this.menu);
    },
    handleSuccessMain(file) {
      this.content.content.listing[this.listingGroupIndex].content[
        this.listingGroupItemIndex
      ].main_img = file.xhr.response;
    },
    handleSuccess(file, i, index) {
      if (file.cropped) {
        this.content.content.listing[i].content[index].img.splice(
          this.content.content.listing[i].content[index].img.indexOf("1"),
          1,
          file.xhr.response
        );
        let item = this.content.content.listing[i].content[index];
        let elements =
          this.$refs[
            `upload_detail_${i}_${index}`
          ][0].$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        this.$refs[`upload_detail_${i}_${index}`][0].dropzone.files = [];
        for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
          let mockFile = {
            accepted: true,
            cropped: true,
            imgRemove: imgInd,
            notRemove: true,
            kind: "image",
            ...item,
          };
          if (this.$refs[`upload_detail_${i}_${index}`][0].dropzone) {
            if (
              !this.$refs[
                `upload_detail_${i}_${index}`
              ][0].dropzone.files.includes(item.img[imgInd])
            ) {
              if (
                this.$refs[`upload_detail_${i}_${index}`][0].dropzone.files
                  .length !== item.img.length
              ) {
                this.$refs[`upload_detail_${i}_${index}`][0].manuallyAddFile(
                  mockFile,
                  item.img[imgInd]
                );
                this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].children[0].src =
                  item.img[imgInd];
                this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].style.borderRadius = 0;
                this.$refs[`upload_detail_${i}_${index}`][0].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[1].style.background = "none";
                this.$refs[`upload_detail_${i}_${index}`][0].dropzone.emit(
                  "complete",
                  mockFile
                );
              }
            }
          }
        }
      }
      if (this.menuType === "submenu") {
        this.$store.state.listing.listingGroup = null;
        this.$store.state.listing.listing = null;
        this.$store.state.listing.subListing = null;
      } else {
        this.$store.state.listing.page = null;
        this.$store.state.listing.listingGroup = null;
        this.$store.state.listing.listing = null;
        this.$store.state.listing.subListing = null;
      }
    },
    handleRemoveMain(file, i, listingIndex) {
      this.content.content.listing[i].content[listingIndex].main_img = "";
      this.setMenu();
    },
    setListingIndexes(groupIndex, itemIndex, d) {
      this.listingGroupIndex = groupIndex;
      this.listingGroupItemIndex = itemIndex;
      if (d === "desc") {
        if (this.menuType === "submenu") {
          this.$store.state.listing.listingGroup = groupIndex;
          this.$store.state.listing.subListing = itemIndex;
        } else {
          this.changeListingPage(this.item.page, groupIndex, itemIndex);
        }
      }
    },
    changeListingPage(page, index, listingIndex) {
      this.$store.commit("setListingPage", {
        index: listingIndex,
        page: page,
        listingGroup: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-component .slidecontainer {
  width: 100%;
  margin-top: 20px;
}

.slider-component .slidecontainer .slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #c2c2c2;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider-component .slidecontainer .slider:hover {
  opacity: 1;
}

.slider-component .slidecontainer .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #d8a22e;
  cursor: pointer;
  border-radius: 50%;
}

.slider-component .slidecontainer .slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: #d8a22e;
  cursor: pointer;
  border-radius: 50%;
}
.custom__menu--color {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: 1px solid #000;
}
</style>
