<template>
  <div>
    <div style="display: flex; align-items: center;justify-content: space-between;margin-bottom:15px;">
      <v-btn
        class="mb-4"
        color="primary"
        depressed
        outlined
        small
        @click="$emit('listingList')"
      >
        <v-icon>mdi-chevron-left</v-icon>Listing
      </v-btn>
      <slot/>
    </div>
    <div>
      <div class="item">
        <label class="label">Name</label>
        <label class="item__label__mini label" v-if="listing.constructor_name">{{ listing.constructor_name }}</label>
        <div class="control" style="display: flex">
        <v-text-field
          outlined
          solo
          flat
          dense
          :placeholder="'Name'"
          v-model="listing.name"
        ></v-text-field>

        <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="listing.show_name_color"
        >
          <template v-slot:activator="{ on }">
            <div
              class="colorpicker"
              :style="`background-color:${listing.name_color}`"
              v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="listing.name_color"
                @input="listing.show_name_color = false"
                flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      </div>
      <div class="item">
        <label class="label">Small description</label>
        <label class="item__label__mini label" v-if="listing.constructor_small_description">{{ listing.constructor_small_description }}</label>
        <div class="control" style="display: flex">
          <v-text-field
              outlined
              solo
              flat
              dense
              :color="``"
              :placeholder="'Small description'"
              v-model="listing.descr"
          >
            <template v-slot:append>
              <v-icon
                  :title="listing.show_descr ? 'Show short description in list' : 'Don`t show short description in the list'"
                  @click="hideSmallDescription(listing)"
                  :color="listing.show_descr ? 'rgb(127, 131, 133)' : '#dddddd'"
              >
                mdi-eye-outline
              </v-icon>
            </template>
          </v-text-field>

          <v-menu
              top
              nudge-left="16"
              nudge-bottom="98"
              :close-on-content-click="false"
              v-model="listing.show_descr_color"
          >
            <template v-slot:activator="{ on }">
              <div
                  class="colorpicker"
                  :style="`background-color:${listing.descr_color}`"
                  v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                    v-model="listing.descr_color"
                    @input="listing.show_descr_color = false"
                    flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div class="item">
        <label class="item__label__mini label" v-if="listing.constructor_video_link">{{ listing.constructor_video_link }}</label>
        <div class="control" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="margin-right: 15px;display: block;margin-bottom: 0">Video</label>
          <v-menu offset-y open-on-hover bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="margin-right: 15px;" color="primary" dark outlined small v-bind="attrs" v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title
                ><label :for="listing.id" style="cursor: pointer"
                >Browse</label>
                  <v-file-input
                      :id="`${listing.id}`"
                                accept="video/*"
                                show-size
                                ref="videoUpload"
                                prepend-icon=""
                                placeholder="Upload video"
                                outlined
                                style="display: none"
                                hide-details
                                dense
                                @change="onFileChange"></v-file-input>
                                    </v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item  link @click="openModalVideoLink">
                                  <v-list-item-title style="cursor: pointer"
                                  >Link</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
            </v-menu>
          <div v-if="listing.video_link">
            Link: {{listing.video_link}}
          </div>
          <div v-if="listing.video">
            Name: {{formatName(listing.video)}}
          </div>
        </div>
      </div>
      <div class="item" style="margin-bottom:15px">
        <label class="label">Full description</label>
        <label class="label item__label__mini" v-if="listing.constructor_full_description">{{ listing.constructor_full_description }}</label>
        <div class="control">
          <Editor
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="options"
              v-model="listing.full_descr"
          ></Editor>
        </div>
      </div>
      <div class="item">
        <label class="label">Main image</label>
        <label class="label item__label__mini" v-if="listing.constructor_main_img">{{ listing.constructor_main_img }}</label>
        <div
            class="control mb-6"
            @click="setListingIndexes(groupIndex, listingIndex)"
        >
          <vue-dropzone
              @vdropzone-drop="setListingIndexes(groupIndex, listingIndex)"
              :id="`upload_${groupIndex}_${listingIndex}`"
              :ref="`upload_${groupIndex}_${listingIndex}`"
              :options="dropzoneOptionsListingMenu"
              style="margin-bottom: 15px"
              maxFiles="2"
              :destroyDropzone="false"
              @vdropzone-files-added="checkMainFiles"
              @vdropzone-mounted="mountDropzoneMain"
              @vdropzone-sending="customEvent"
              @vdropzone-removed-file="handleRemoveMain"
              @vdropzone-success="handleSuccessMain"
          >
          </vue-dropzone>
        </div>
      </div>
      <div class="item">
        <label class="label">Detail images</label>
        <label class="label item__label__mini" v-if="listing.constructor_img">{{ listing.constructor_img }}</label>
        <div
            class="control mb-6"
            @click="setListingIndexes(groupIndex, listingIndex, 'desc')"
        >
          <vue-dropzone
              :id="`upload_detail_${groupIndex}_${listingIndex}`"
              :ref="`upload_detail_${groupIndex}_${listingIndex}`"
              :options="dropzoneOptionsListingDetailMenu"
              :destroyDropzone="false"
              @vdropzone-drop="setListingIndexes(groupIndex, listingIndex)"
              style="margin-bottom: 15px"
              @vdropzone-mounted="mountDropzone"
              @vdropzone-sending="customEvent"
              @vdropzone-removed-file="
            (file) => handleRemove(file, groupIndex, listingIndex)
          "
              @vdropzone-success="
            (file) => handleSuccess(file, groupIndex, listingIndex)
          "
          >
          </vue-dropzone>
        </div>
      </div>
      <div v-if="listing.img.length > 1">
        <label class="label">You can change image order</label>
        <draggable
            :options="{disabled:isMobile}"
            style="display: flex; margin-bottom: 10px"
            :list="listing.img"
        >
          <div
              v-for="(img, index) in listing.img"
              :key="img"
              style="margin-right: 15px; position: relative"
          >
            <div
                style="
                border: 2px solid #007db5;
                color: #007db5;
                padding: 3px 6px;
                font-weight: bold;
                background: #fafafa;
                position: absolute;
                top: 0;
                left: 0;
              "
            >
              {{ index + 1 }}
            </div>
            <img :src="img" alt="" style="width: 150px; height: 100px" />
          </div>
        </draggable>
      </div>
      <FormOrder :item="listing" style="margin:15px 0;" :menu="menu" />
      <PopupCrop title='Change main image before upload?' ref="PopupCrop" @confirm="openEditor" @save="saveImg" @close="closeCrop"/>
      <PopupCrop title='Change detail image before upload?' ref="PopupCropDetail" @confirm="openEditorDetail" @save="saveImgDetail" @close="closeCropDetail"/>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="video_link_modal" max-width="700" eager @click:outside="closeModalVideoLink">
      <v-card>
        <v-btn class="popup__close" small icon @click="closeModalVideoLink">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Add video link
        </v-card-title>
        <v-card-text>
          <div class="card_body">
            <div class="card_body_item" style="display: flex;margin-bottom:15px;align-items: center">
              <label class="label" style="display:block;margin-bottom: 0;width:75px;">Link</label>
              <v-text-field placeholder="https://" outlined dense hide-details v-model.number="video_link">
              </v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
          <v-btn @click="saveModalVideoLink" outlined small color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import FormOrder from "../FormOrder.vue";
import draggable from "vuedraggable";
import Cropper from "cropperjs";
import "../../../../../node_modules/cropperjs/dist/cropper.min.css";
import Compressor from "compressorjs";
import { mapState } from "vuex";
export default {
  props: [
    "content",
    "listing",
    "menuType",
    "groupIndex",
    "listingIndex",
    "menu",
    "item",
  ],
  name: "ListingDetail",
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data() {
    return {
      isMobile:false,
      video_link_modal:false,
      video_link:"",
      listingPages:null,
      dropzoneOptionsListingDetailMenu: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        parallelUploads: 1,
        uploadMultiple: false,
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        init: function() {
              this.handleFiles = function(files) {
                  var file, _i, _len, _results;
                  _results = [];
                  for (_i = 0, _len = files.length; _i < _len; _i++) {
                      file = files[_i];
                      _results.push(this.addFile(file));
                      break;
                  }
                  return _results;
              };
              this._addFilesFromItems = function(items) {
                  var entry, item, _i, _len, _results;
                  _results = [];
                  for (_i = 0, _len = items.length; _i < _len; _i++) {
                      item = items[_i];
                      if ((item.webkitGetAsEntry != null) && (entry = item.webkitGetAsEntry())) {
                          if (entry.isFile) {
                              _results.push(this.addFile(item.getAsFile()));
                          } else if (entry.isDirectory) {
                              _results.push(this._addFilesFromDirectory(entry, entry.name));
                          } else {
                              _results.push(void 0);
                          }
                      } else if (item.getAsFile != null) {
                          if ((item.kind == null) || item.kind === "file") {
                              _results.push(this.addFile(item.getAsFile()));
                          } else {
                              _results.push(void 0);
                          }
                      } else {
                          _results.push(void 0);
                      }
                      break;
                  }
                  return _results;
              };
          },
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compressDetail(file)
          }
        },
      },
      dropzoneOptionsListingMenu: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        init: function() {
              this.handleFiles = function(files) {
                  var file, _i, _len, _results;
                  _results = [];
                  for (_i = 0, _len = files.length; _i < _len; _i++) {
                      file = files[_i];
                      _results.push(this.addFile(file));
                      // Make sure we don't handle more files than requested
                      if (this.options.maxFiles != null && this.options.maxFiles > 0 && _i >= (this.options.maxFiles - 1)) {
                          break;
                      }
                  }
                  return _results;
              };
              this._addFilesFromItems = function(items) {
                  var entry, item, _i, _len, _results;
                  _results = [];
                  for (_i = 0, _len = items.length; _i < _len; _i++) {
                      item = items[_i];
                      if ((item.webkitGetAsEntry != null) && (entry = item.webkitGetAsEntry())) {
                          if (entry.isFile) {
                              _results.push(this.addFile(item.getAsFile()));
                          } else if (entry.isDirectory) {
                              _results.push(this._addFilesFromDirectory(entry, entry.name));
                          } else {
                              _results.push(void 0);
                          }
                      } else if (item.getAsFile != null) {
                          if ((item.kind == null) || item.kind === "file") {
                              _results.push(this.addFile(item.getAsFile()));
                          } else {
                              _results.push(void 0);
                          }
                      } else {
                          _results.push(void 0);
                      }
                      // Make sure we don't handle more files than requested
                      if (this.options.maxFiles != null && this.options.maxFiles > 0 && _i >= (this.options.maxFiles - 1)) {
                          break;
                      }
                  }
                  return _results;
              };
          },
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.listingPages = JSON.parse(JSON.stringify(this.$store.state.listing));
            if (this.menuType === "submenu") {
              this.$store.state.listing.listingGroup = null;
              this.$store.state.listing.subListing = null;
            } else {
              this.$store.commit("setPage", this.item.page);
              this.$store.state.listing.listingGroup = null;
              this.$store.state.listing.subListing = null;
            }
            this.compress(file)
          }
        },
      },
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: `a[title=button] {
          max-width: 360px;
          height: 40px;
          min-width: 71px;
          padding: 0 17.7777777778px;
          align-items: center;
          border-radius: 5px;
          display: inline-flex;
          flex: 0 0 auto;
          font-weight: 500;
          letter-spacing: normal;
          justify-content: center;
          outline: 0;
          position: relative;
          text-decoration: none;
          text-indent: normal;
          text-transform: none;
          transition-duration: 0.28s;
          transition-property: box-shadow, transform, opacity;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          vertical-align: middle;
          white-space: nowrap;
        }`,
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
    };
  },
  components: {
    vueDropzone,
    FormOrder,
    draggable,
    PopupCrop:() => import('@/components/Popup/PopupCrop'),
  },
  computed: {
    ...mapState(["landing"]),
  },
  methods: {
    openModalVideoLink(){
      if(this.listing.video_link){
        this.video_link = this.listing.video_link.split('').join('');
      }else{
        this.video_link = "";
      }
      this.video_link_modal = true;
    },
    closeModalVideoLink(){
      this.video_link_modal = false;
    },
    saveModalVideoLink(){
      if(this.listing.video){
        this.listing.video = "";
      }
      this.listing.video_link = this.video_link.split('').join('');
      this.video_link_modal = false;
    },
    formatName(video_link){
      return video_link.slice(video_link.lastIndexOf('/')+1);
    },
    async onFileChange(file){
      try{
        if(file){
          let formData = new FormData();
          formData.append('hotel_id',+this.$route.params.id);
          formData.append('file',file);
          const response = await this.$axios.post('upload-video',formData);
          if(this.listing.video_link){
            this.listing.video_link = '';
          }
          if(!this.listing.video){
            this.listing.video = null;
          }
          this.listing.video = response.data;
        }
      }catch(e){
        console.log(e);
      }
    },
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let sliderHeight = document.createElement("div");
      sliderHeight.style.position = "absolute";
      sliderHeight.style.bottom = "20px";
      sliderHeight.style.left = "24px";
      sliderHeight.style.zIndex = "10";
      let height =
          this.item.styles.displayType === "inside"
              ? this.content.styles.height
              : this.item.styles.listing_img_height;
      sliderHeight.innerHTML +=
          ' <div class="slider-component"><span class="listingHeight" style="color:#fff;font-size:18px; font-family: Inter, sans-serif;">Change height ' +
          height +
          ' px</span><div class="slidecontainer"><input style="width:300px;" ref="input" type="range" min="50" max="500" value="' +
          height +
          '" class="slider"></div></div>';
      let sliderInputHeight =
          sliderHeight.getElementsByClassName("slider")[0];
      sliderInputHeight.addEventListener("input", (e) => {
        let ratio;
        if (this.item.styles.displayType === "horizontal") {
          ratio =
              +this.item.styles.listing_img_width /
              +this.item.styles.listing_img_height;
          this.landing.advanced.global.listing_img_height = e.target.value;
          this.item.styles.listing_img_height = e.target.value;
          let listingHeight =
              sliderHeight.getElementsByClassName("listingHeight")[0];
          listingHeight.innerHTML =
              "Change height " + this.item.styles.listing_img_height + " px";
          cropper.setAspectRatio(ratio);
          cropper.previews[0].cropperPreview.width =
              +this.item.styles.listing_img_width;
          cropper.previews[0].cropperPreview.height =
              +this.item.styles.listing_img_height;
        } else if (this.item.styles.displayType === "default") {
          ratio = 300 / +this.item.styles.listing_img_height;
          this.landing.advanced.global.listing_img_height = e.target.value;
          this.item.styles.listing_img_height = e.target.value;
          let listingHeight =
              sliderHeight.getElementsByClassName("listingHeight")[0];
          listingHeight.innerHTML =
              "Change height " + this.item.styles.listing_img_height + " px";
          cropper.setAspectRatio(ratio);
          cropper.previews[0].cropperPreview.width = 300;
          cropper.previews[0].cropperPreview.height =
              +this.item.styles.listing_img_height;
        } else {
          ratio = 300 / +this.content.styles.height;
          this.content.styles.height = e.target.value;
          let listingHeight =
              sliderHeight.getElementsByClassName("listingHeight")[0];
          listingHeight.innerHTML =
              "Change height " + this.content.styles.height + " px";
          cropper.setAspectRatio(ratio);
          cropper.previews[0].cropperPreview.width = 300;
          cropper.previews[0].cropperPreview.height =
              +this.content.styles.height;
        }
      });
      let sliderWidth = document.createElement("div");
      sliderWidth.style.position = "absolute";
      sliderWidth.style.bottom = "20px";
      sliderWidth.style.left = "350px";
      sliderWidth.style.zIndex = "10";
      sliderWidth.innerHTML +=
          ' <div class="slider-component"><span class="listingWidth" style="color:#fff;font-size:18px;font-family: Inter, sans-serif;">Change width ' +
          this.item.styles.listing_img_width +
          ' px</span><div class="slidecontainer"><input ref="input" style="width:300px;" type="range" min="90" max="300" value="' +
          this.item.styles.listing_img_width +
          '" class="slider"></div></div>';
      let sliderInputWidth =
          sliderWidth.getElementsByClassName("slider")[0];
      sliderInputWidth.addEventListener("input", (e) => {
        this.landing.advanced.global.listing_img_width = e.target.value;
        this.item.styles.listing_img_width = e.target.value;
        let ratio;
        if (this.item.styles.displayType === "horizontal") {
          ratio =
              +this.item.styles.listing_img_width /
              +this.item.styles.listing_img_height;
          let listingWidth =
              sliderWidth.getElementsByClassName("listingWidth")[0];
          listingWidth.innerHTML =
              "Change width " + this.item.styles.listing_img_width + " px";
          cropper.setAspectRatio(ratio);
          cropper.previews[0].cropperPreview.width =
              +this.item.styles.listing_img_width;
          cropper.previews[0].cropperPreview.height =
              +this.item.styles.listing_img_height;
        }
      });

      editor.appendChild(sliderHeight);

      if (this.item.styles.displayType === "horizontal") {
        editor.appendChild(sliderWidth);
      }
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      if (
          this.content.content.listing[this.groupIndex].content[
              this.listingIndex
              ].main_img
      ) {
        this.$refs[
            `upload_${this.groupIndex}_${this.listingIndex}`
            ].dropzone.removeAllFiles();
      }
      buttonCancel.addEventListener("click", () => {
        this.$refs[
            `upload_${this.groupIndex}_${this.listingIndex}`
            ].dropzone.removeAllFiles();
        this.content.content.listing[this.groupIndex].content[
            this.listingIndex
            ].preview = false;
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
    newFile.cropped = true;
    newFile.imgRemove =
        this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`]
            .dropzone.files.length - 1;

    newFile.name = file.name;
    this.$refs[
        `upload_${this.groupIndex}_${this.listingIndex}`
        ].dropzone.removeAllFiles();
    this.$refs[
        `upload_${this.groupIndex}_${this.listingIndex}`
        ].dropzone.addFile(newFile);
    this.$refs[
        `upload_${this.groupIndex}_${this.listingIndex}`
        ].dropzone.processQueue();
    this.$refs[
        `upload_${this.groupIndex}_${this.listingIndex}`
        ].dropzone["previewsContainer"].children[
        this.$refs[
            `upload_${this.groupIndex}_${this.listingIndex}`
            ].dropzone.files.length
        ].children[0].children[0].src = canvas.toDataURL(file.type);
    this.$refs[
        `upload_${this.groupIndex}_${this.listingIndex}`
        ].dropzone["previewsContainer"].children[
        this.$refs[
            `upload_${this.groupIndex}_${this.listingIndex}`
            ].dropzone.files.length
        ].children[0].style.borderRadius = 0;
    this.$refs[
        `upload_${this.groupIndex}_${this.listingIndex}`
        ].dropzone["previewsContainer"].children[
        this.$refs[
            `upload_${this.groupIndex}_${this.listingIndex}`
            ].dropzone.files.length
        ].children[1].style.background = "none";
    document.body.removeChild(editor);
    this.content.content.listing[this.groupIndex].content[
        this.listingIndex
        ].preview = false;
    cropper.destroy();
    localStorage.setItem("update", "true");
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });

      let ratio;
      if (this.item.styles.displayType === "horizontal") {
        ratio =
            +this.item.styles.listing_img_width /
            +this.item.styles.listing_img_height;
      } else if (this.item.styles.displayType === "default") {
        ratio = 300 / +this.item.styles.listing_img_height;
      } else {
        ratio =
            300 /
            +document.getElementById(
                `listing_${this.groupIndex}_${this.listingIndex}`
            ).clientHeight;
      }
      let preview;
      if (this.item.styles.displayType === "inside") {
        this.content.content.listing[this.groupIndex].content[
            this.listingIndex
            ].preview = true;
        preview = `.preview__inside__${this.groupIndex}__${this.listingIndex}`;
      } else {
        this.content.content.listing[this.groupIndex].content[
            this.listingIndex
            ].main_img = {sad:"asd"};
        preview = `.listing__item__img__${this.groupIndex}__${this.listingIndex}`;
      }
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      let cropper = new Cropper(image, {
        preview: preview,
        aspectRatio: ratio,
      });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone.removeAllFiles();
      this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone.addFile(file);
      this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone.processQueue();
      this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    closeCrop(){
      this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone.removeAllFiles();
    },
    closeCropDetail(){
      let item = this.content.content.listing[this.groupIndex].content[this.listingIndex];
      let elements =
          this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].$el.getElementsByClassName(
              "dz-preview"
          );
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone.files = [];
      for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
        let mockFile = {
          accepted: true,
          cropped: true,
          imgRemove: imgInd,
          notRemove: true,
          kind: "image",
          ...item,
        };
        if (this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone) {
          if (
              !this.$refs[
                  `upload_detail_${this.groupIndex}_${this.listingIndex}`
                  ].dropzone.files.includes(item.img[imgInd])
          ) {
            if (
                this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone.files
                    .length !== item.img.length
            ) {

              this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].manuallyAddFile(
                  mockFile,
                  item.img[imgInd]
              );
              this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone[
                  "previewsContainer"
                  ].children[imgInd + 1].children[0].children[0].src =
                  item.img[imgInd];
              this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone[
                  "previewsContainer"
                  ].children[imgInd + 1].children[0].style.borderRadius = 0;
              this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone[
                  "previewsContainer"
                  ].children[imgInd + 1].children[1].style.background = "none";
              this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone.emit(
                  "complete",
                  mockFile
              );
            }
          }
        }
      }
    },
    openEditorDetail(file){
        let editor = document.createElement("div");
        editor.style.position = "fixed";
        editor.style.left = 0;
        editor.style.right = 0;
        editor.style.top = 0;
        editor.style.bottom = 0;
        editor.style.width = "66%";
        editor.style.zIndex = 9999;
        editor.style.backgroundColor = "#000";
        document.body.appendChild(editor);
        let buttonConfirm = document.createElement("button");
        buttonConfirm.style.position = "absolute";
        buttonConfirm.style.right = "10px";
        buttonConfirm.style.top = "10px";
        buttonConfirm.style.zIndex = 9999;
        buttonConfirm.textContent = "Save image";
        buttonConfirm.className = "v-btn v-size--small confirm-control";
        editor.appendChild(buttonConfirm);
        let buttonCancel = document.createElement("button");
        buttonCancel.style.position = "absolute";
        buttonCancel.style.right = "135px";
        buttonCancel.style.top = "10px";
        buttonCancel.style.zIndex = 9999;
        buttonCancel.textContent = "Cancel";
        buttonCancel.className = "v-btn v-size--small confirm-control";
        editor.appendChild(buttonCancel);
        let slider = document.createElement("div");
        slider.style.position = "absolute";
        slider.style.bottom = "20px";
        slider.style.left = "24px";
        slider.style.zIndex = "10";
        slider.innerHTML +=
            ' <div class="slider-component"><span class="listingHeight" style="color:#fff;font-size:18px; font-family: Inter, sans-serif;">Change height ' +
            this.item.styles.detail_listing_img_height +
            ' px</span><div class="slidecontainer"><input ref="input" type="range" style="width:300px;" min="90" max="300" value="' +
            this.item.styles.detail_listing_img_height +
            '" class="slider"></div></div>';

        let sliderInput = slider.getElementsByClassName("slider")[0];
        sliderInput.addEventListener("input", (e) => {
          this.landing.advanced.global.detail_listing_img_height =
              e.target.value;
          this.item.styles.detail_listing_img_height = e.target.value;
          let ratio = 345 / +this.item.styles.detail_listing_img_height;
          let listingHeight =
              slider.getElementsByClassName("listingHeight")[0];
          listingHeight.innerHTML =
              "Change height " +
              this.item.styles.detail_listing_img_height +
              " px";
          cropper.setAspectRatio(ratio);
          cropper.previews[0].cropperPreview.width = 345;
          cropper.previews[0].cropperPreview.height =
              +this.item.styles.detail_listing_img_height;
        });
        editor.appendChild(slider);
        buttonCancel.addEventListener("click", () => {
          file.imgRemove =
              this.listing.img.length - 1;
          this.$refs[
              `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.removeFile(file);
          document.body.removeChild(editor);
          cropper.destroy();
        });
        if (this.menuType === "submenu") {
          this.$store.state.listing.listingGroup = this.groupIndex;
          this.$store.state.listing.subListing = this.listingIndex;
        } else {
          this.changeListingPage(
              this.item.page,
              this.groupIndex,
              this.listingIndex
          );
        }
        let buttonRotateLeft = document.createElement("button");
        buttonRotateLeft.className = "cropper-controls";
        buttonRotateLeft.style.left = "24px";
        buttonRotateLeft.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
        editor.appendChild(buttonRotateLeft);
        let buttonRotateRight = document.createElement("button");
        buttonRotateRight.className = "cropper-controls";
        buttonRotateRight.style.left = "64px";
        buttonRotateRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
        editor.appendChild(buttonRotateRight);
        let buttonCrop = document.createElement("button");
        buttonCrop.className = "cropper-controls active-control";
        buttonCrop.style.left = "104px";
        buttonCrop.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
        editor.appendChild(buttonCrop);
        let buttonZoomPlus = document.createElement("button");
        buttonZoomPlus.className = "cropper-controls";
        buttonZoomPlus.style.left = "144px";
        buttonZoomPlus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
        editor.appendChild(buttonZoomPlus);
        let buttonZoomMinus = document.createElement("button");
        buttonZoomMinus.className = "cropper-controls";
        buttonZoomMinus.style.left = "184px";
        buttonZoomMinus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
        editor.appendChild(buttonZoomMinus);
        let buttonLeftRight = document.createElement("button");
        buttonLeftRight.className = "cropper-controls";
        buttonLeftRight.style.left = "224px";
        buttonLeftRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
        editor.appendChild(buttonLeftRight);
        let buttonUpDown = document.createElement("button");
        buttonUpDown.className = "cropper-controls";
        buttonUpDown.style.left = "264px";
        buttonUpDown.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
        editor.appendChild(buttonUpDown);
        buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
      let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
      newFile.cropped = true;
      newFile.imgRemove = this.content.content.listing[
          this.groupIndex
          ].content[this.listingIndex].length
          ? 0
          : this.content.content.listing[this.groupIndex].content[
              this.listingIndex
              ].length;

      newFile.name = file.name;
      const candidate = this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.files.find(x => x.status === 'queued');
      if(candidate){
        this.$refs[
            `upload_detail_${this.groupIndex}_${this.listingIndex}`
            ].dropzone.removeFile(candidate);
      }
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.removeFile(file);
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.addFile(newFile);
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.processQueue();
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone["previewsContainer"].children[
          this.$refs[
              `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.length
          ].children[0].children[0].src = canvas.toDataURL(file.type);
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone["previewsContainer"].children[
          this.$refs[
              `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.length
          ].children[0].style.borderRadius = 0;
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone["previewsContainer"].children[
          this.$refs[
              `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.length
          ].children[1].style.background = "none";
      document.body.removeChild(editor);
      cropper.destroy();
      localStorage.setItem("update", "true");
          });
          buttonRotateRight.addEventListener("click", () => {
            if (!buttonRotateRight.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonRotateRight.classList.add("active-control");
            }
            cropper.rotate(45);
          });
          buttonRotateLeft.addEventListener("click", () => {
            if (!buttonRotateLeft.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonRotateLeft.classList.add("active-control");
            }
            cropper.rotate(-45);
          });

          buttonCrop.addEventListener("click", () => {
            if (!buttonCrop.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonCrop.classList.add("active-control");
            }
            cropper.setDragMode("crop");
          });
          buttonZoomPlus.addEventListener("click", () => {
            if (!buttonZoomPlus.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonZoomPlus.classList.add("active-control");
            }
            cropper.zoom(0.1);
          });
          buttonZoomMinus.addEventListener("click", () => {
            if (!buttonZoomMinus.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonZoomMinus.classList.add("active-control");
            }
            cropper.zoom(-0.1);
          });
          buttonLeftRight.addEventListener("click", () => {
            if (!buttonLeftRight.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonLeftRight.classList.add("active-control");
            }
            if (cropper.getData().scaleX === 1) {
              cropper.scaleX(-1);
            } else {
              cropper.scaleX(1);
            }
          });
          buttonUpDown.addEventListener("click", () => {
            if (!buttonUpDown.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonUpDown.classList.add("active-control");
            }
            if (cropper.getData().scaleY === 1) {
              cropper.scaleY(-1);
            } else {
              cropper.scaleY(1);
            }
          });
          let image = new Image();
          image.src = URL.createObjectURL(file);
          editor.appendChild(image);
          this.content.content.listing[this.groupIndex].content[
              this.listingIndex
              ].img.push("1");
          let cropper = new Cropper(image, {
            preview: `.preview_detail`,
            aspectRatio: 345 / +this.item.styles.detail_listing_img_height,
            responsive: false,
          });
    },
    async saveImgDetail(file){
      file.cropped = true;
      const candidate = this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.files.find(x => x.status === 'queued');
      if(candidate){
        this.$refs[
            `upload_detail_${this.groupIndex}_${this.listingIndex}`
            ].dropzone.removeFile(candidate);
      }
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.addFile(file);
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.processQueue();
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone["previewsContainer"].children[
          this.$refs[
              `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.length
          ].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone["previewsContainer"].children[
          this.$refs[
              `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.length
          ].children[0].style.borderRadius = 0;
      this.$refs[
          `upload_detail_${this.groupIndex}_${this.listingIndex}`
          ].dropzone["previewsContainer"].children[
          this.$refs[
              `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.length
          ].children[1].style.background = "none";
    },
    compressDetail(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCropDetail.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        }
      })
    },
    checkMainFiles(files){
        if(files.length>1){
            for(let i = 0;i<files.length;i++){
                if(i !== files.length-1){
                  //
                }
            }
        }
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    mountDropzone() {
      if (this.listing.img.length) {
        for (let imgInd = 0; imgInd < this.listing.img.length; imgInd++) {
          let mockFile = {
            accepted: true,
            cropped: true,
            imgRemove: imgInd,
            kind: "image",
            ...this.listing,
          };
          if (
            this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`]
              .dropzone
          ) {
            if (
              !this.$refs[
                `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.includes(this.listing.img[imgInd])
            ) {
              if (
                this.$refs[
                  `upload_detail_${this.groupIndex}_${this.listingIndex}`
                ].dropzone.files.length !== this.listing.img.length
              ) {
                this.$refs[
                  `upload_detail_${this.groupIndex}_${this.listingIndex}`
                ].manuallyAddFile(mockFile, this.listing.img[imgInd]);
                this.$refs[
                  `upload_detail_${this.groupIndex}_${this.listingIndex}`
                ].dropzone["previewsContainer"].children[
                  imgInd + 1
                ].children[0].children[0].src = this.listing.img[imgInd];
                this.$refs[
                  `upload_detail_${this.groupIndex}_${this.listingIndex}`
                ].dropzone["previewsContainer"].children[
                  imgInd + 1
                ].children[0].style.borderRadius = 0;
                this.$refs[
                  `upload_detail_${this.groupIndex}_${this.listingIndex}`
                ].dropzone["previewsContainer"].children[
                  imgInd + 1
                ].children[1].style.background = "none";
                this.$refs[
                  `upload_detail_${this.groupIndex}_${this.listingIndex}`
                ].dropzone.emit("complete", mockFile);
              }
            }
          }
        }
      }
    },
    handleSuccess(file, i, index) {
      if(this.content.content.listing[this.groupIndex].content[this.listingIndex].img.indexOf("1") >= 0){
        this.content.content.listing[this.groupIndex].content[this.listingIndex].img.splice(
            this.content.content.listing[this.groupIndex].content[this.listingIndex].img.indexOf("1"),
            1,
            file.xhr.response
        );
      }else{
        this.content.content.listing[this.groupIndex].content[this.listingIndex].img.push(file.xhr.response);
      }
      let item = this.content.content.listing[this.groupIndex].content[this.listingIndex];
      let elements =
        this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].$el.getElementsByClassName(
          "dz-preview"
        );
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone.files = [];
      for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
          let mockFile = {
            accepted: true,
            cropped: true,
            imgRemove: imgInd,
            notRemove: true,
            kind: "image",
            ...item,
          };
          if (this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone) {
            if (
              !this.$refs[
                `upload_detail_${this.groupIndex}_${this.listingIndex}`
              ].dropzone.files.includes(item.img[imgInd])
            ) {
              if (
                this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone.files
                  .length !== item.img.length
              ) {

                this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].manuallyAddFile(
                  mockFile,
                  item.img[imgInd]
                );
                this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].children[0].src =
                  item.img[imgInd];
                this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].style.borderRadius = 0;
                this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[1].style.background = "none";
                this.$refs[`upload_detail_${this.groupIndex}_${this.listingIndex}`].dropzone.emit(
                  "complete",
                  mockFile
                );
              }
            }
          }
        }
    },
    handleSuccessMain(file) {
      this.content.content.listing[this.groupIndex].content[this.listingIndex].main_img = null;
      this.$nextTick(() => {
        this.content.content.listing[this.groupIndex].content[this.listingIndex].main_img = file.xhr.response;
      });
      this.$store.state.listing = JSON.parse(JSON.stringify(this.listingPages));
    },
    customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    mountDropzoneMain() {
      if (this.listing.main_img) {
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.listing,
        };
        if (
          this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`]
            .dropzone &&
          this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone
            .files.length === 0
        ) {
          this.$refs[
            `upload_${this.groupIndex}_${this.listingIndex}`
          ].manuallyAddFile(mockFile, this.listing.main_img);
          this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone[
            "previewsContainer"
          ].children[1].children[0].children[0].src = this.listing.main_img;
          this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone[
            "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
          this.$refs[`upload_${this.groupIndex}_${this.listingIndex}`].dropzone[
            "previewsContainer"
          ].children[1].children[1].style.background = "none";
          this.$refs[
            `upload_${this.groupIndex}_${this.listingIndex}`
          ].dropzone.emit("complete", mockFile);
        }
      }
    },
    async handleRemove(file, i, listingIndex) {
      if (file.imgRemove || file.imgRemove === 0) {
        this.content.content.listing[i].content[listingIndex].img.splice(
          file.imgRemove,
          1
        );
        let item = this.content.content.listing[i].content[listingIndex];
        let elements =
          this.$refs[
            `upload_detail_${i}_${listingIndex}`
          ].$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        this.$refs[`upload_detail_${i}_${listingIndex}`].dropzone.files = [];
        for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
          let mockFile = {
            accepted: true,
            cropped: true,
            imgRemove: imgInd,
            notRemove: true,
            kind: "image",
            ...item,
          };
          if (this.$refs[`upload_detail_${i}_${listingIndex}`].dropzone) {
            if (
              !this.$refs[
                `upload_detail_${i}_${listingIndex}`
              ].dropzone.files.includes(item.img[imgInd])
            ) {
              if (
                this.$refs[`upload_detail_${i}_${listingIndex}`].dropzone.files
                  .length !== item.img.length
              ) {
                this.$refs[
                  `upload_detail_${i}_${listingIndex}`
                ][0].manuallyAddFile(mockFile, item.img[imgInd]);
                this.$refs[`upload_detail_${i}_${listingIndex}`].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].children[0].src =
                  item.img[imgInd];
                this.$refs[`upload_detail_${i}_${listingIndex}`].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[0].style.borderRadius = 0;
                this.$refs[`upload_detail_${i}_${listingIndex}`].dropzone[
                  "previewsContainer"
                ].children[imgInd + 1].children[1].style.background = "none";
                this.$refs[`upload_detail_${i}_${listingIndex}`].dropzone.emit(
                  "complete",
                  mockFile
                );
              }
            }
          }
        }
      }
    },
    handleRemoveMain(file) {
        if(file.main_img === this.listing.main_img || file?.xhr?.response&&file.xhr.response === this.listing.main_img){
            this.listing.main_img = "";
        }else{
            if (file.previewElement && file.previewElement.parentNode) {
                console.log(file);
                file.previewElement.parentNode.removeChild(file.previewElement);
            }
        }
    },
    hideSmallDescription(listing) {
      listing.show_descr = !listing.show_descr;
    },
    setListingIndexes(groupIndex, itemIndex, d) {
      this.groupIndex = groupIndex;
      this.listingIndex = itemIndex;
      if (d === "desc") {
        if (this.menuType === "submenu") {
          this.$store.state.listing.listingGroup = groupIndex;
          this.$store.state.listing.subListing = itemIndex;
        } else {
          this.changeListingPage(this.item.page, groupIndex, itemIndex);
        }
      }
    },
    changeListingPage(page, index, listingIndex) {
      this.$store.commit("setListingPage", {
        index: listingIndex,
        page: page,
        listingGroup: index,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.item{
  display:flex;
  flex-direction:column;
  &__label__mini{
    color:#7e7e7e;
  }
}
</style>
