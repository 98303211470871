import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.localPage === 'table')?_c('div',[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.groups,"mobile-breakpoint":"0","hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('draggable',{attrs:{"options":{disabled: _vm.isMobile},"list":_vm.groups,"tag":"tbody"}},_vm._l((items),function(item,i){return _c('tr',{key:item.id},[_c('td',{staticStyle:{"width":"300px","text-align":"center"},on:{"click":function($event){return _vm.$emit('changePage', i)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex-direction":"column"}},[_c('label',{staticClass:"label",staticStyle:{"width":"300px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","margin":"0 auto","display":"block"}},[_vm._v(_vm._s(_vm.getText(item.title)))]),(item.constructor_table_title)?_c('label',{staticClass:"label",staticStyle:{"color":"#7e7e7e"}},[_vm._v(_vm._s(item.constructor_table_title))]):_vm._e()])]),_c('td',{staticStyle:{"text-align":"center","cursor":"pointer"},on:{"click":function($event){return _vm.$emit('changePage', i)}}},[_vm._v(" "+_vm._s(item.content.length)+" ")]),_c('td',{staticStyle:{"text-align":"center","align-items":"center","justify-content":"center"}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VSwitch,{staticStyle:{"margin-left":"-5px"},attrs:{"color":"#6ab64a","hide-details":""},model:{value:(item.hide),callback:function ($$v) {_vm.$set(item, "hide", $$v)},expression:"item.hide"}})],1)],1),_c('td',{staticStyle:{"text-align":"center"}},[_c(VMenu,{attrs:{"offset-y":"","open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":"","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openEdit(item, i)}}},[_c(VListItemTitle,[_vm._v("Edit listing group title")])],1),_c(VListItem,[_c(VListItemTitle,{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('changePage', i)}}},[_vm._v(" Show item list ")])],1),_c(VDivider),_c(VListItem,[_c(VListItemTitle,{staticStyle:{"cursor":"pointer","color":"red"},on:{"click":function($event){return _vm.openDeleteDialog(i)}}},[_vm._v("Delete")])],1)],1)],1)],1)])}),0):_vm._e(),(items.length === 0)?_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"7"}},[_vm._v(" You don't have listing group yet, it's time to create them ")])])]):_vm._e()]}}],null,false,839013766)})],1):_vm._e(),(_vm.localPage === 'edit')?_c('div',[_c('ListingEditCategory',{attrs:{"edited-category":_vm.editedCategory},on:{"listingList":_vm.listingList}})],1):_vm._e(),_c('PopupDelete',{ref:"PopupDeleteCategory",attrs:{"title":("Are your sure listing group " + (_vm.deleteIndex+1) + " delete?")},on:{"confirm":_vm.deleteCategory,"saveEdit":_vm.saveEdit}}),_c('PopupSuccess',{ref:"PopupSuccess",attrs:{"title":'Thank You!',"content":_vm.successMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }